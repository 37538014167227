import { IUserInfo } from '@core/models/auth/IDecodedToken.interface';
import { ILoginState } from '@core/models/auth/ILoginState.interface';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

const initialLoginState: ILoginState = {
  userInfo: null,
  isLogged: false,
  invalidCredentials: false,
  isLoading: false,
};

export const LoginStore = signalStore(
  { providedIn: 'root' },
  withState(initialLoginState),
  withMethods((store) => ({
   setUserInfo(newUserInfo: IUserInfo) {
    patchState(store, { userInfo: newUserInfo, isLogged: true });
   },
    resetUserInfo() {
      patchState(store, { userInfo: null, isLogged: false });
    },
    setUserLanguage(language: string) {
      if (!store.userInfo()) return;
      patchState(store, { userInfo: { ...store.userInfo()!, language } });
    }
  }))
);
